import Vue from 'vue'
import VueRouter from 'vue-router'
import routes from './routes'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})


// 获取原型对象上的push函数
const originalPush = VueRouter.prototype.push
// 修改原型对象中的push方法
VueRouter.prototype.push = function push (location) {
  return originalPush.call(this, location).catch(err => err)
}
router.beforeEach((to, from, next) => {

  if (to.meta.title) {
    document.title = to.meta.title
  }
  
  // 判断是否需要缓存
  // to.meta.keepAlive = false;
  // from.meta.keepAlive = false;
  // to.meta.keepAlive = true
  // console.log(to, from)
  // if(to.path == '/reserve' && from.path =='/confirmOrder'){
  //   to.meta.keepAlive = true;  // 让 列表页 缓存，即不刷新
  // }else {
  //   to.meta.keepAlive = false;  // 让 列表页 即不缓存，刷新
  // }

  // if (to.meta.requireAuth) {
  //   let token = ''
  //   if (localStorage.getItem('token') != null) {
  //     token = localStorage.getItem('token')
  //   } else {
  //     next({
  //       path: '/login',
  //       query: {
  //         redirect: to.fullPath
  //       }
  //     })
  //   }
  //   if (token != ''){
  //     next()
  //   } else {
  //     next({
  //       path: '/login',
  //       query: {
  //         redirect: to.fullPath
  //       }
  //     })
  //   }
  // } else {
  //   next()
  // }
  next()
})

export default router
