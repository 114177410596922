import Home from '../views/index.vue'
import Reserve from '../views/reserve.vue'
import ConfirmOrder from '../views/confirmOrder.vue'
import PaySuccess from '../views/paySuccess.vue'
import Pay from '../views/pay.vue'
import CardIndex from '../views/card/cardIndex.vue'
import CardExchange from '../views/card/cardExchange.vue'
import CardInfoConfirm from '../views/card/cardInfoConfirm.vue'
import JumpPay from '@/views/jumpPay'
import QrCodeLink from '@/components/qrCodeLink.vue'
import QrCodeDetail from '@/components/qrCodeDetail.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home,
    meta: {
      title: '首页',
      keepAlive: false,
      deepth: 1 // 定义路由的层级
    }
  },
  {
    path: '/reserve',
    name: 'reserve',
    component: Reserve,
    meta: {
      title: '预定',
      keepAlive: false,
      deepth: 2 // 定义路由的层级
    }
  },
  {
    path: '/confirmOrder',
    name: 'confirmOrder',
    component: ConfirmOrder,
    meta: {
      title: '订单确认',
      keepAlive: false,
      deepth: 3 // 定义路由的层级
    }
  },
  {
    path: '/pay',
    name: 'pay',
    component: Pay,
    meta: {
      title: '支付',
      keepAlive: false,
      deepth: 5 // 定义路由的层级
    }
  },
  {
    path: '/paySuccess',
    name: 'paySuccess',
    component: PaySuccess,
    meta: {
      title: '支付成功',
      keepAlive: false,
      deepth: 6 // 定义路由的层级
    }
  },
  {
    path: '/cardindex',
    name: 'cardindex',
    component: CardIndex,
    meta: {
      title: '会员卡兑换',
      keepAlive: false,
      deepth: 5.5 // 定义路由的层级
    }
  },
  {
    path: '/cardExchange',
    name: 'cardExchange',
    component: CardExchange,
    meta: {
      title: '会员卡兑换',
      keepAlive: false,
      deepth: 6 // 定义路由的层级
    }
  },
  {
    path: '/confirm',
    name: 'confirm',
    component: CardInfoConfirm,
    meta: {
      title: '信息确认',
      keepAlive: false,
      deepth: 7 // 定义路由的层级
    }
  },
  {
    path: '/jumppay',
    name: 'jumpPay',
    component: JumpPay,
    meta: {
      title: '支付详情',
    }
  },
  {
    path: '/qrCodeDetail',
    name: 'qrCodeDetail',
    component: QrCodeDetail,
    meta: {
      title: '订单详情',
    }
  },
  {
    path: '/qrCodeLink',
    name: 'qrCodeLink',
    component: QrCodeLink,
    meta: {
      title: '订单详情',
    }
  }
]

export default routes