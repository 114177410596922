<template>
  <div class="card-exchange">
    <div class="main">
      <div class="info">
        <div class="item">
          <span>兑换码</span>
          <input type="text" v-model="orderForm.code" @blur="getCardInfo(orderForm.code)" placeholder="请输入兑换码">
          <span class="err">兑换码有误，请重新输入！</span>
        </div>
        <div class="item">
          <span>手机号</span>
          <input type="text" v-model="orderForm.phone" @blur="getPhoneFlag(orderForm.phone)" placeholder="请输入手机号">
        </div>
        <div class="item"  v-if="orderForm.ptId != 2">
          <span>会员姓名</span>
          <input type="text" v-model="orderForm.name" placeholder="请输入姓名">
        </div>
        <div class="item"  v-if="orderForm.ptId == 2">
          <span>姓(拼音)</span>
          <input type="text" v-model="orderForm.pinYinXing" placeholder="Surname,如LI">
        </div>
        <div class="item"  v-if="orderForm.ptId == 2">
          <span>名(拼音)</span>
          <input type="text" v-model="orderForm.pinYinMing" placeholder="Given name,如MEIMEI">
        </div>
        <div class="item">
          <span>证件类型</span>
            <input type="text" readonly @focus="IdTypeShow=true" v-model="IdType">
            <van-popup v-model="IdTypeShow" position="bottom" :style="{ height: '50%' }">
              <van-picker
                show-toolbar
                :columns="IdTypeNameList"
                @cancel="IdTypeShow = false"
                @confirm="checkIdType"
              />
            </van-popup>
        </div>
        <div class="item">
          <span>证件号码</span>
          <input type="text" v-model="orderForm.cardNo" placeholder="请输入证件号码">
        </div>
        <div class="item sex">
          <span>性别</span>
          <van-radio-group v-model="orderForm.sex" direction="horizontal">
            <van-radio
              v-for="item in sexList"
              :key="item.code"
              :name="item.code"
              :disabled="orderForm.sexFlag && item.code!=orderForm.sex"
            >{{ item.value }}</van-radio>
          </van-radio-group>
        </div>
        <div class="item"  v-if="orderForm.ptId!=1 && orderForm.ptId != ''">
          <span>出生日期</span>
          <input type="text" v-model="orderForm.birthday" @click="show=true" placeholder="请输入">
          <van-popup v-model="show" position="bottom" :style="{ height: '50%' }">
            <van-datetime-picker
              v-model="nowDate"
              :min-date="minDate"
              :max-date="maxData"
              type="date"
              :formatter="formatter"
              @cancel="show=false"
              @confirm="checkDate"
            />
          </van-popup>
        </div>
        <div class="item"  v-if="orderForm.ptId!=1 && orderForm.ptId!=3 && orderForm.ptId!=5 && orderForm.ptId != ''">
          <span>有效期至</span>
          <input type="text" v-model="orderForm.paperValidTime" @click="showValid=true" placeholder="请输入">
          <van-popup v-model="showValid" position="bottom" :style="{ height: '50%' }">
            <van-datetime-picker
              v-model="nowDate"
              :min-date="minDate"
              :max-date="maxData"
              type="date"
              :formatter="formatter"
              @cancel="showValid=false"
              @confirm="checkValid"
            />
          </van-popup>
        </div>
        <div class="item address"  v-if="orderForm.ptId==2">
          <span>签发地</span>
          <input type="text" readonly @focus="IssuePlaceShow=true && (placekey='')" v-model="orderForm.issueAt">
          <van-popup v-model="IssuePlaceShow" closeable position="bottom" :style="{ height: '70%' }">
            <h2>请选择国家或地区</h2>
            <div>
              <van-icon name="search" />
              <input type="text" v-model="placekey" placeholder="中国 / CN / cn">
            </div>
            <ul>
              <li
                v-for="item in (searchList.length > 0 ? searchList : IssuePlaceList)"
                :key="item.index"
                @click="checkIssuePlace(item)"
              >{{ item.areaName }}<span v-if="item.areaName!='无结果'">({{ item.areaCode }}.{{ item.ename }})</span></li>
            </ul>
          </van-popup>
        </div>
      </div>
      <div class="card-info" v-if="cardInfo">
        <div class="tit">{{ cardInfo.name }}</div>
        <img :src="cardInfo.imgUrl" alt="">
        <div class="intro">
          <div class="qy">
            <h2>会员权益</h2>
            <div class="qy-item">
              <div v-for="item in equitiesInfoList " :key="item.index">
                <span>{{ item.etName }}</span>
                <span>{{ item.eiValue }}{{ item.euName }}</span>
              </div>
            </div>
          </div>
          <div class="card-intro">
            <h2>详情介绍</h2>
            <div>
              <p v-html="cardInfo.remark"></p>
            </div>
        </div>
        </div>
      </div>
    </div>
    <div class="btn">
      <div class="price">总价：<span>¥0</span><span>¥{{ cardInfo.price }}</span></div>
      <button @click="submit()">提交</button>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import WxValidate from '@/lib/WxValidate'
import { getageType,judgeBirth } from '@/lib/utils'
export default {
  name: 'CardExchange',
  data() {
    return{
      show: false,
      showValid: false,
      maxData: new Date(2100, 0, 1),
      minDate: new Date(1800, 0, 1),
      nowDate: new Date(),
      cardInfo: '',
      equitiesInfoList: [],
      sexList: [], // 性别列表
      orderForm: {
        name: '',
        pinYinXing: '', //姓（拼音)
        pinYinMing: '', //名（拼音)
        phone: '',
        cardNo: '',
        ptId: '1', // 证件类型
        sex: 1, // 性别
        code: '', // 兑换码
        sexFlag: false,
        paperValidTime: '', // 有效期
        issueAt: '', // 签发地
        birthday: '', // 生日
        phoneFlag:false,//f-未注册
      },
      IdTypeShow:false,
      IdTypeNameList: [], // 证件类型名称列表
      IdTypeList: '', // 证件类型列表
      IdType: '身份证', // 所选的证件类型名称
      IssuePlaceShow: false,
      IssuePlaceList: [], // 签发地列表
      placekey: '',
      searchList: '',
    }
  },
  mounted() {
    this.getBaseInfo()
  },
  methods: {
    formatter(type, val) {
      if (type === 'year') {
        return val + '年';
      }
      if (type === 'month') {
        return val + '月';
      }
      if (type === 'day') {
        return val + '日';
      }
      return val;
    },
    checkDate(date) {
      this.show = false
      this.orderForm.birthday = moment(date).format('YYYY-MM-DD')
    },
    checkValid(date) {
      this.showValid = false
      this.orderForm.paperValidTime = moment(date).format('YYYY-MM-DD')
    },
    // 选择证件类型
    checkIdType(val, index) { 
      this.IdTypeShow = false
      this.IdType = val
      this.IdTypeInfo = this.IdTypeList[index]
      this.orderForm.ptId = this.IdTypeInfo.code
    },
    // 请求所兑换的会员卡数据
    getCardInfo (val) {
      if (null != val && '' != val) {
        this.request({
          url: "/product/rcMemberProduct/useTravelCard/byCode",
          method: "get",
          params: {
            code: val
          }
        }).then(resp => {
          console.log("190", resp);
          if (resp.code == 200) {
            this.cardInfo = resp.data.travelCard;
            this.equitiesInfoList = resp.data.equitiesInfoList;
            console.log("106", this.cardInfo)
            console.log("107", this.equitiesInfoList)
            if (this.cardInfo.imgUrl && this.cardInfo.imgUrl != '{}') {
              var parse = JSON.parse(this.cardInfo.imgUrl);
              if (parse.length > 0) {
                this.cardInfo.imgUrl = parse[0].url;
              }
            }

          } else {
            this.$toast(resp.msg) // 需要反馈到页面上
          }
        })
      }
    },
    // 获取证件类型
    getBaseInfo() {
      this.request({
        url: '/customer/getInitComp',
        method: 'GET'
      })
      .then(res => {
        // console.log(res)
        if(res.code == 200) {
          this.IdTypeList = res.data.papersTypeList
          this.sexList = res.data.travelSexList
          // console.log(this.sexList)
          let idtypeList = this.IdTypeList.filter(
            (ft) => ft.code == this.orderForm.ptId)
            // console.log(idtypeList[0].value)
          this.IdType = idtypeList[0].value
          this.IdTypeInfo = idtypeList[0]
          for (var i=0; i < this.IdTypeList.length; i++){
            this.IdTypeNameList.push(this.IdTypeList[i].value)
          }
        }
      })
    },
    // 验证手机号是否被注册
    async getPhoneFlag(val){
      let reg = /^1[345789]\d{9}$/
      if(reg.test(val)){
        let that=this
        await this.request({
          url:"/customer/memberInfo/checkPhoneForVip?str="+val.trim(),
          method: 'GET'
        })
        .then(res=>{
          console.log(res)
            if(res.code==200){
              that.orderForm.phoneFlag=false
            }else{
              that.orderForm.phoneFlag=true
              this.$dialog.confirm({
                title: '提示',
                message: '该贵宾已经是公司会员，是否继续办卡',
              })
              .then(() => {
                
              })
              .catch(() => {
                this.$router.back()
              })
            }
        }) 
      }else{
        this.$toast('请输入正确的手机号！')
      }
           
    },
    initValidate() {
      let user = this.user
      // console.log(user)
      if(!this.puKaTag || (this.puKaTag && user.cardNo)){
          const rules = {
          name: {
            required: true,
            name: true,
            // rareWord:true,
          },
          pinYinXing: {
            required: true,
            pinyin: true,
          },
          pinYinMing: {
            required: true,
            pinyin: true,
          },
          cardNo: {
            required: true,
          },
          paperValidTime: {
            required: true,
          },
          issueAt: {
            required: true,
          },
          birthday: {
            required: true,
          },
          phone: {
            required: true,
            tel: true,
          },
        }
        const messages = {
          name: {
            required: '请输入乘客姓名',
            name: '姓名不合法，请参照证件填写说明填写',
            // rareWord:'姓名中包含生僻字,请参照证件填写说明填写',
          },
          pinYinXing: {
            required: '请检查姓（拼音）的正确性',
            pinyin: '请检查姓（拼音）的正确性',
          },
          pinYinMing: {
            required: '请检查名（拼音）的正确性',
            pinyin: '请检查名（拼音）的正确性',
          },
          cardNo: {
            required: '请输入有效的证件号',
          },
          paperValidTime: {
            required: '请选择证件有效截止日期',
          },
          issueAt: {
            required: '请选择证件签发地',
          },
          birthday: {
            required: '请选择出生日期',
          },
          phone: {
            required: '请输入有效的手机号',
            tel: '请输入有效的手机号',
          },
        }
        if (user.ptId == 1) {
          //身份证
          let fields = ['paperValidTime', 'issueAt', 'birthday']
          if (user.travelType != 3) {
            fields = ['pinYinXing', 'pinYinMing'].concat(fields)
          }
          rules.cardNo.cardNo = true
          messages.cardNo.cardNo = '请输入有效的证件号'
          for (var i = 0; i < fields.length; i++) {
            delete rules[fields[i]]
            delete messages[fields[i]]
          }
        } else {
          if (user.ptId == 2) {
            //护照
            delete rules['name']
            delete messages['name']
          } else {
            //其他证件
            let fields = ['issueAt']
            if (user.ptId == 3 || user.ptId == 4 || user.ptId == 9) {
              //不需要有效期
              fields = ['paperValidTime'].concat(fields)
            }
            if (user.travelType != 3) {
              fields = ['pinYinXing', 'pinYinMing'].concat(fields)
            }
            for (var i = 0; i < fields.length; i++) {
              delete rules[fields[i]]
              delete messages[fields[i]]
            }
          }
        }

        if (user.travelType == 2 || user.travelType == 3) {
          delete rules['phone']
          delete messages['phone']
        }
        let initValidate = new WxValidate(rules, messages)
        initValidate.addMethod('name', (value) => {
          return (
            initValidate.optional(value) ||
            (value.length >= 2 &&
              /(^[A-z]+\/[A-z]+$)|((?![a-zA-Z]+$)^[\u4e00-\u9fa5A-z]+$)/.test(
                value
              ))
          )
        })
        initValidate.addMethod('rareWord',(value)=>{
        let pinyin=wpinyin.getPinyin(value);
          return initValidate.optional(value) || !/.*[\u4e00-\u9fa5]+.*$/.test(pinyin);
        })

        if (user.ptId == 1) {
          initValidate.addMethod('cardNo', (value) => {
            if (
              /^[1-9]\d{5}\d{2}((0[1-9])|10|11|12)(([0-2][1-9])|10|20|30|31)\d{2}[0-9]$/.test(
                value
              )
            ) {
              value = this.trans15to18(value)
            }
            let date = moment().format('YYYYMMDD')
            return (
              initValidate.optional(value) ||
              (/^[1-9]\d{5}(18|19|([23]\d))\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/.test(
                value
              ) &&
                value.substring(6, 14) <= date)
            )
          })
        } else if (user.ptId == 2) {
          initValidate.addMethod('pinyin', (value) => {
            return initValidate.optional(value) || /^[a-zA-Z]+$/.test(value)
          })
        }
        // console.log(rules, messages, initValidate)
        return initValidate
      }
    },
    // 提交
    submit() {
      console.log("orderForm",this.orderForm)
    },
  }
}
</script>

<style lang="less">
.card-exchange{
  min-height: 100vh;
  background: url('../../assets/exchangebg.png') no-repeat;
  background-size: 100% 100%;

  .main{
    padding-bottom: 70px;
    .info{
      width: 100%;
      background: transparent;
      padding-top: 50px;
      box-sizing: border-box;
      padding: 50px 20px 0;
      .item{
        width: 100%;
        height: 60px;
        display: flex;
        justify-content: space-between;
        position: relative;
        >:first-child{
          align-self: center;
          font-size: 20px;
          font-weight: bold;
          font-family: Microsoft YaHei-Bold, Microsoft YaHei;
        }
        input{
          align-self: center;
          border: 1px solid #707070;
          height: 35px;
          border-radius: 30px;
          padding-left: 15px;
          box-sizing: border-box;
          font-size: 18px;
          line-height: 35px;
        }
        .err{
          position: absolute;
          right: 30px;
          bottom: -10px;
          color: red;
        }
      }
      .sex{
        .van-radio--horizontal{
          margin-right: 50px;
        }
        .van-radio-group--horizontal{
          align-self: center;
          height: 35px;
          background: #fff;
          width: 236px;
          border: 1px solid #707070;
          border-radius: 30px;
          padding-left: 15px;
          box-sizing: border-box;
        }
      }
      .address{
        .van-popup{
          padding: 0 20px;
          box-sizing: border-box;
          h2{
            font-weight: normal;
            font-size: 16px;
            text-align: center;
            margin-top: 20px;
          }
          div{
            display: flex;
            position: relative;
            .van-icon{
              position: absolute;
              font-size: 24px;
              color: #b4b6c5;
              top: 18px;
              left: 10px;
            }
            input{
              display: inline-block;
              width: 100%;
              height: 40px;
              border-radius: 5px;
              margin: 10px 0;
              padding-left: 40px;
              box-sizing: border-box;
              background: #eeeeee;
            }
          }
          ul{
            margin-top: 10px;
            overflow: scroll;
            height: 355px;
            li{
              height: 40px;
              border-bottom: 1px solid #eeeeee;
              font-size: 16px;
              line-height: 40px;
              color: #333333;
              font-size: 14px;
            }
          }
          ul::-webkit-scrollbar  {  
            display: none!important;
            width: 0px;  
            height: 0px;  
          }
        }
      }
    }
    .card-info{
      padding: 0 20px;
      box-sizing: border-box;
      .tit{
        font-size: 20px;
        font-weight: bold;
        font-family: Microsoft YaHei-Bold, Microsoft YaHei;
        margin-bottom: 20px;
      }
      img{
        width: 100%;
        margin-bottom: 20px;
      }
      .intro{
        .qy{
          width: 100%;
          padding: 15px;
          box-sizing: border-box;
          margin-bottom: 20px;
          background: #fff;
          border-radius: 15px;
          >h2{
            font-size: 16px;
            padding-left: 10px;
            border-left: 5px solid #0084f4;
            box-sizing: border-box;
            margin-bottom: 10px;
            color: #333333;
          }
          .qy-item>div{
            display: flex;
            justify-content: space-between;
            padding: 4px 0;
          }
        }
        .card-intro{
          width: 100%;
          padding: 15px;
          box-sizing: border-box;
          margin-bottom: 20px;
          background: #fff;
          border-radius: 15px;
          >h2{
            font-size: 16px;
            padding-left: 10px;
            border-left: 5px solid #0084f4;
            box-sizing: border-box;
            margin-bottom: 10px;
            color: #333333;
          }
        }
      }
    }
  }

  .btn{
    width: 100%;
    position: fixed;
    background: #fff;
    bottom: 0;
    display: flex;
    height: 60px;
    display: flex;
    justify-content: space-between;
    padding: 0 20px;
    box-sizing: border-box;

    .price{
      align-self: center;
      :first-child{
        color: red;
        font-size: 20px;
      }
      :last-child{
        text-decoration: line-through;
        color: #999;
        margin-left: 10px;
        font-size: 14px;
      }
    }

    button{
      background: rgb(238, 188, 96);
      width: 130px;
      height: 40px;
      border-radius: 30px;
      align-self: center;
      color: #fff;
    }
  }
}
</style>
