<template>
  <div class="cardinfo-confirm">
    <img src="https://filestore.hydtrip.net/group1/M00/00/07/L2jfKV6ZFJyAP1viAAI6bakQhQQ042.png" alt="">
    <div class="user">
      <div class="tit"><span>用户信息</span><span class="edit">修改信息</span></div>
      <div class="main">
        <div class="item"><span>姓名</span><span>1111111111</span></div>
        <div class="item"><span>手机号</span><span>11111</span></div>
        <div class="item"><span>身份证号</span><span>1111111111</span></div>
        <div class="item"><span>出生日期</span><span>111111</span></div>
        <div class="item"><span>兑换码</span><span>11111111111111</span></div>
      </div>
    </div>
    <div class="oth">
      <div class="othbtn"><button>电子协议</button><button>会员签名</button></div>
      <p><span>*注：</span>请仔细核对用户信息是否正确！</p>
    </div>
    <div class="submit">
      <div class="price">总价：<span>¥0</span><span>¥0</span></div>
      <button>提交</button>
    </div>
  </div>
</template>

<script>
export default {
  name: ''
}
</script>

<style lang="less" scoped>
.cardinfo-confirm{
  min-height: 100vh;
  background: url('../../assets/exchangebg.png') no-repeat;
  background-size: 100% 100%;
  padding: 15px 20px 0;
  box-sizing: border-box;

  img{
    width: 100%;
    margin-bottom: 15px;
  }
  .user{
    background: #fff;
    border-radius: 15px;
    padding: 15px;
    box-sizing: border-box;
    .tit{
      display: flex;
      justify-content: space-between;
      font-weight: bold;
      font-size: 18px;
      margin-bottom: 5px;
      .edit{
        color: rgba(139, 8, 8, 0.61);
      }
    }
    .main{
      .item{
        padding: 8px 0;
        display: flex;
        font-size: 18px;
        box-sizing: border-box;
        border-bottom: 1px solid rgb(231, 231, 231);
        >:first-child{
          width: 100px;
        }
        >:last-child{
          font-weight: bold;
        }
      }
      >:last-child{
        border: none;
      }
    }
  }
  .oth{
    margin-top: 10px;
    .othbtn{
      button{
        width: 120px;
        height: 38px;
        border: 1px solid #ccc;
        border-radius: 30px;
        background: #fff;
        margin-right: 10px;
        font-weight: bold;
        font-size: 18px;
        color: rgb(87, 110, 241);
        text-decoration: underline;
      }
      :last-child{
        color: rgb(223, 181, 66);
      }
    }
    p{
      margin-top: 10px;
      color: red;
    }
  }
  .submit{
    width: 100%;
    position: fixed;
    background: #fff;
    bottom: 0;
    left: 0;
    display: flex;
    height: 60px;
    display: flex;
    justify-content: space-between;
    padding: 0 20px;
    box-sizing: border-box;

    .price{
      align-self: center;
      :first-child{
        color: red;
        font-size: 20px;
      }
      :last-child{
        text-decoration: line-through;
        color: #999;
        margin-left: 10px;
        font-size: 14px;
      }
    }

    button{
      background: rgb(238, 188, 96);
      width: 130px;
      height: 40px;
      border-radius: 30px;
      align-self: center;
      color: #fff;
    }
  }
}
</style>
