<template>
  <div v-html="content" ref="reportHTML">
      {{content}}
  </div>
</template>

<script>
export default {
    data(){
      return {
        content:''
      }
    },
  mounted(){
    let form = this.$route.query.content
    this.content = form
    let JSScript="";
    form.replace(/<script.*?>([\s\S]+?)<\/script>/img,function(_,js){    //正则匹配出script中的内容
        JSScript = js
    })
    // 这里需要使用$nextTick  需要等dom更新后才能操作reportHTML
    this.$nextTick(() => {
        // 将js脚本内容插入到标签当中
        var newElement = document.createElement("script")
        newElement.innerHTML = JSScript
        this.$refs.reportHTML.append(newElement)
    })
  }
}
</script>

<style>

</style>
