<template>
  <div class="pay">
    <div class="header">
      <img src="../assets/header.jpg" alt="">
    </div>
    <div class="main">
      <h2>订单确认：</h2>
      <div class="bot">
        <div><span>支付金额：</span><span style="color: red;">¥ {{ cost }}</span></div>
        <div><span>联系电话：</span><span>{{ phone }}</span></div>
        <div><span>订单编号：</span><span>{{ orderNo }}</span></div>
        <p><span>注：</span>为保证安全，请您对付款信息确认无误后再支付</p>
      </div>
      <div class="btn">
        <button @click="pay">支付 ¥ {{ cost }}</button>
      </div>
    </div>
    <div class="footer" v-if="false">
      <img src="../assets/footer.jpg" alt="">
    </div>
  </div>
</template>

<script>
import qs from 'qs'
import axios from "axios";
export default {
  name: 'Pay',
  data(){
    return{
      cost: 0,
      phone: '',
      orderNo: ''
    }
  },
  mounted() {
    this.phone = this.$route.query.phone
    this.orderNo = this.$route.query.orderNo
    this.cost = this.$route.query.cost
  },
  methods: {
    pay() {
      let base_url = window.location.origin
      let url = base_url + '/paySuccess'
      this.request({
        url: `/order/h5/allinpay/pay?orderNo=${this.orderNo}&orderType=1&url=${url}`,
        method: 'GET'
      })
      .then(res => {
        console.log('返回结果',res)
        window.location.href=`https://syb.allinpay.com/apiweb/h5unionpay/unionorder?${qs.stringify(res)}`
        // this.request({
        //   url: 'http://h5public.shallsay.cn/api/apiweb/h5unionpay/unionorder',
        //   // url:  'http://192.168.3.98:8080/v1/apiweb/h5unionpay/unionorder',
        //   method: 'POST',
        //   data:qs.stringify(res),
        //   headers:{
        //     "Access-Control-Allow-Origin": "*",
        //     "Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept",
        //     "content-type":"application/x-www-form-urlencoded"
        //   }
        // }).then(res=>{
        //     let routeData = this.$router.resolve({ name: 'jumpPay', query: {  content: res } })
        //     // window.open(routeData.href, '_blank') // 苹果手机不支持
        //     window.location = routeData.href
        // })
      })
    }
  }
}
</script>

<style lang="less">
.pay{
  min-height: 100vh;
  position: relative;
  background: #f7f7f7;
  .header, .footer{
    width: 100%;
    height: 167px;
    background: #cfe;
    img{
      width: 100%;
      height: 100%;
    }
  }
  .footer{
    position: absolute;
    bottom: 0;
    left: 0;
  }

  .main{
    margin-top: 30px;
    padding: 15px 20px 0;
    position: relative;
    box-sizing: border-box;
    height: 50vh;
    h2{
      font-weight: bold;
      padding-left: 20px;
      font-size: 20px;
    }
    .bot{
      padding: 20px 20px 0;
      box-sizing: border-box;
      margin-top: 20px;
      // background: #fff;
      border-radius: 15px;

      >div{
        display: flex;
        margin-bottom: 10px;
        :first-child{
          width: 90px;
        }
        :last-child{
          font-weight: bold;
        }
      }
      p{
        font-size: 14px;
          color: #666;
        span{
          color: red;
        }
      }
    }
    .btn{
      text-align: center;
      position: absolute;
      width: 100%;
      left: 0;
      bottom: 0;
      button{
        width: 90%;
        background: #169bd5;
        color: #fff;
        height: 36px;
        border-radius: 30px;
      }
    }
  }
}
</style>
