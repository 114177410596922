<template>
  <div class="home">
    <div class="tit">海云端贵宾兑换码</div>
    <div class="main">
      <div class="num">
        <span>您的出行人数....</span><span><van-stepper min="0" v-model="value" input-width="60px" integer theme="round" button-size="34px" /></span>
      </div>
      <div class="btn"><van-button @click="reserve" :disabled="value<=0" round type="info">立即预约</van-button></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Home',
  data() {
    return {
      value: 0
    }
  },
  methods: {
    reserve () {
      this.$router.push({
        path: '/reserve',
        query: {
          num: this.value
        }
      })
    }
  }
}
</script>

<style lang="less">
.home{
  height: 100vh;
  background: url('../assets/bg.jpg') no-repeat;
  background-size: 100% 100%;

  .tit{
    text-align: center;
    font-size: 24px;
    color: #81d3f8;
    padding-top: 60px;
    box-sizing: border-box;
    font-weight: bold;
  }

  .main{
    width: 100%;
    height: 30%;
    position: fixed;
    bottom: 0;
    left: 0;
    .num{
      display: flex;
      justify-content: center;
      >:first-child{
        font-size: 32px;
        font-weight: bold;
        color: #fff;
        margin-right: 10px;
      }
      >:last-child{
        align-self: center;
        .van-stepper{
          display: inline-block;
          .van-stepper__plus, .van-stepper__minus{
            background-color: #fff;
            border: 0;
          }
          .van-stepper__minus::after, .van-stepper__minus::before, .van-stepper__plus::after, .van-stepper__plus::before{
            background-color: #000;
          }
          .van-stepper__minus::before, .van-stepper__plus::before{
            height: 4px;
          }
          .van-stepper__minus::after, .van-stepper__plus::after{
            width: 4px;
          }
          .van-stepper__input{
            font-size: 32px;
            color: #fff;
          }
        }
      }
    }
    .btn{
      margin-top: 37px;
      text-align: center;
      .van-button--round{
        border-radius: 20px;
      }
      .van-button{
        border: 0;
        width: 192px;
        height: 50px;
        font-size: 20px;
      }
      .van-button--disabled{
        opacity: 1;
        background: #aaaaaa;
      }
    }
  }
}
</style>
