
export function  getimg(imgUrl){
  if(!imgUrl){
    return imgUrl
  }  

  let image=JSON.parse(imgUrl)
  console.log(image)
  let images=[]
  image.forEach(item => {
    images.push(item.response.data)
  })
  let img = images[0]
  if (img.startsWith('http')) {
    let arr = img.split('//')[1].split('/')
    arr[0] = 'https://filestore.hydtrip.net'
    return arr.join('/')
  } else {
    console.log('转化失败', img)
    return img
  }
}



export  function getageType(age){
  let travelType='';
  let travelTypeText='';
 if(0<=age&&age<2){
     travelType=3;//婴儿
     travelTypeText = "婴儿";
   }else if(age>=12){
     travelType=1;//成人
     travelTypeText = "成人";
   }else if(2<=age&&age<12){
     travelType=2;//儿童
     travelTypeText ="儿童";
   }
  return {
  travelType:travelType,
  travelTypeText:travelTypeText
  }
}

export function judgeBirth(date,now=new Date()){//yyyymmdd
  
  var date=date.toString().replace(/-/g,'');
  // console.log(date,"进来了")
  var birYear = date.substring(0, 4); //年
  var birMonth = date.substring(4,6); //月
  var birDay = date.substring(6); //日
  var birth=birYear+"-"+birMonth+"-"+birDay;//yyyy-mm-dd
  var n = now;
  var nowYear = n.getFullYear();
  var nowMonth = n.getMonth() + 1; //记得加1
  var nowDay = n.getDate();
  var returnAge;
  if (date==null || date=="") {
      return false
  };
  var d = new Date(birYear, birMonth - 1, birDay);

  if (d.getFullYear() == birYear && (d.getMonth() + 1) == birMonth && d.getDate() == birDay) {
      // console.log("1")
      if (nowYear == birYear) {
          var ageDiff=0;
          if (nowMonth == birMonth) {
              var dayDiff = nowDay - birDay; // 
              if (dayDiff < 0) {
                  returnAge = ageDiff - 1;
              } else {
                  returnAge = ageDiff;
              }
          } else {
              var monthDiff = nowMonth - birMonth; // 
              if (monthDiff < 0) {
                  returnAge = ageDiff - 1;
              } else {
                  returnAge = ageDiff;
              }
          } 
      } else {
          var ageDiff = nowYear - birYear; // 
          if (ageDiff > 0) {
              if (nowMonth == birMonth) {
                 
                  var dayDiff = nowDay - birDay; // 
                  if (dayDiff < 0) {
                      returnAge = ageDiff - 1;
                  } else {
                      returnAge = ageDiff;
                  }
              } else {
                  var monthDiff = nowMonth - birMonth; // 
                  if (monthDiff < 0) {
                      returnAge = ageDiff - 1;
                  } else {
                      returnAge = ageDiff;
                  }
              }
          } else {
              return  -1; //返回-1 表示出生日期输入错误 晚于今天
          }
      }
      return returnAge;
  } else {
      return -2;
  }

}

function getLastNumber(id){
  let lastNumber='1';
  var getNum=parseInt(id.charAt(0))*7+parseInt(id.charAt(1))*9+parseInt(id.charAt(2))*10+parseInt(id.charAt(3))*5+parseInt(id.charAt(4))*8+parseInt(id.charAt(5))*4+parseInt(id.charAt(6))*2+parseInt(id.charAt(7))*1+parseInt(id.charAt(8))*6+parseInt(id.charAt(9))*3+parseInt(id.charAt(10))*7+parseInt(id.charAt(11))*9+parseInt(id.charAt(12))*10+parseInt(id.charAt(13))*5+parseInt(id.charAt(14))*8+parseInt(id.charAt(15))*4+parseInt(id.charAt(16))*2;

getNum=getNum%11;
switch (getNum) {
case 0 :
lastNumber="1";
break;
case 1 :
lastNumber="0";
break;
case 2 :
lastNumber="X";
break;
case 3 :
lastNumber="9";
break;
case 4 :
lastNumber="8";
break;
case 5 :
lastNumber="7";
break;
case 6 :
lastNumber="6";
break;
case 7 :
lastNumber="5";
break;
case 8 :
lastNumber="4";
break;
case 9 :
lastNumber="3";
break;
case 10 :
lastNumber="2";
break;
}
return lastNumber;
}

export default {
  getimg,
  getageType,
  judgeBirth,
  getLastNumber
}
