<template>
  <div id="app">
    <keep-alive>
      <router-view  v-if="$route.meta.keepAlive" />
    </keep-alive>
    <router-view  v-if="!$route.meta.keepAlive" />
  </div>
</template>

<script>
export default {
  data () {
    return {
      include: []
    }
  },
  watch: {
    // $route(to,from){
    //   console.log(to,from)
    //   //如果 要 to(进入) 的页面是需要 keepAlive 缓存的，把 name push 进 include数组
    //   if (to.meta.keepAlive) {
    //     !this.include.includes(to.name) && this.include.push(to.name)
    //     console.log(this.include)
    //   }
    //   //如果 要 form(离开) 的页面是 keepAlive缓存的，
    //   //再根据 deepth 来判断是前进还是后退
    //   //如果是后退
    //   // if (from.meta.keepAlive && to.meta.deepth > from.meta.deepth) {
    //   //   console.log('2222')
    //   //   var index = this.include.indexOf(from.name)
    //   //   index !== -1 && this.include.splice(index, 1)
    //   // }
    // }
  }
}
</script>


<style lang="less">
body {
  background: #fff;
}
html,body,ol,ul,li,h1,h2,h3,h4,h5,h6,p,th,td,dl,dd,form,fieldset,legend,input,textarea,select {
  margin: 0;
  padding: 0;
  font-weight: normal;
}
ul,ol,li {
  list-style: none;
}
a,u {
  text-decoration: none;
}
input,fieldset {
  outline: none;
  border: 0;
}
button {
  border: none;
}
</style>
