<template>
  <div class="qrcode-link">
    <h2>海云端贵宾服务订单</h2>
    <div class="order-info">
      <img :src="img" alt="">
      <div class="main-info">
        <div class="price">
          <div class="item"><span>{{ orderInfo.orderStatusText }}</span><span class="mount"><span v-if="orderInfo.orderAmount">¥ {{ orderInfo.orderAmount }}</span> ¥ {{ orderInfo.realAmount }} </span></div>
          <div class="item time"><span>有效期:</span><span>{{ orderInfo.useStartTime }}-{{ orderInfo.useEndTime }}</span></div>
        </div>
        <div class="detail">
          <div class="top" @click="getIntoDetail"><span>{{ productInfo.productName }}</span><span>查看详情<van-icon name="arrow" /></span></div>
          <p>{{ productInfo.addressDetail }}</p>
        </div>
      </div>
    </div>
    <div class="redeem-code">
      <div class="tit"  @click="showCode=!showCode">
        <div>核销码</div>
        <div>查看详情<van-icon name="arrow" /></div>
      </div>
      <div class="code" v-show="showCode">
        <div class="qrcode" ref="qrCodeUrl"></div>
        <p class="replay" @click="refresh(orderInfo.applyCode)"><van-icon name="replay" />手动刷新</p>
        <p class="red"><van-count-down ref="countDown" format="ss" :time="time" @finish="timeFresh" />s后自动刷新二维码</p>
        <p class="blue">{{ orderInfo.applyCode }}</p>
      </div>
      
    </div>
    <div class="cus-info">
      <div class="tit">
        <div>客户信息</div>
        <div @click="getMore">更多<van-icon name="arrow-down" /></div>
      </div>
      <div class="info">
        <ul v-for="item in customerList" :key="item.id">
          <li><span class="first">联系人：</span><span class="last">{{ item.name }}</span></li>
          <li><span class="first">联系方式：</span><span class="last">{{ item.tel }}</span></li>
          <li><span class="first">身份证号码：</span><span class="last">{{ item.idCardNum }}</span></li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import QRCode from 'qrcodejs2'
import moment from 'moment'
import aes_util from "@/lib/aes_util.js"
export default {
  name: '',
  data () {
    return {
      showCode: false,
      orderNo: '', // 订单号
      orderInfo: '',
      customerList: [], // 客户信息
      showCustomerList: [], // 客户信息
      img: '', 
      productInfo: '',
      time: 60* 1000, // 定时器
      isTimeout:false //判断二维码是否失效，默认为false
    }
  },
  mounted() {
    this.orderNo = this.$route.query.orderNo
    this.getOrderInfo()
  },

  methods: {
    getOrderInfo(){
      this.request({
        url: 'order/order/member-service-order/no?orderNo=' + this.orderNo,
        method: 'GET',
      })
      .then(res => {
        console.log(res)
        if(res.code == 200){
          this.orderInfo = res.data.memberServiceOrder
          if(this.orderInfo){
            this.orderInfo.useStartTime = moment(this.orderInfo.useStartTime).format('YYYY年MM月DD日')
            this.orderInfo.useEndTime = moment(this.orderInfo.useEndTime).format('YYYY年MM月DD日')
          }
          this.img = res.data.img
          this.customerList = res.data.consumerList
          this.showCustomerList = this.customerList[0]
          this.productInfo = res.data.productInfo
          this.creatQrCode(this.orderInfo.applyCode)
        }
      })
    },
    getMore() {
      // 点击更多，获取更多的客户信息
      if(this.customerList.length == 1){
        this.$toast('无更多客户信息')
      }else{
        this.showCustomerList = this.customerList
      }
    },
    // 进入详情页
    getIntoDetail () {
      this.$router.push({
        path: '/qrCodeDetail',
        query: {
          info: JSON.stringify(this.productInfo),
          img: this.img
        }
      })
    },
    // 生成二维码
    creatQrCode(val) {
      let longTime=new Date().getTime()+120*1000
      let codeText={
        type:1,
        vipId:val,
        longTime:longTime,
      }
      let arr = aes_util.AesEncrypt(JSON.stringify(codeText))
      // console.log(arr)
      this.$refs.qrCodeUrl.innerHTML = ""
      var qrcode = new QRCode(this.$refs.qrCodeUrl, {
          text: arr, // 需要转换为二维码的内容
          width: 140,
          height: 140,
          colorDark: '#000000',
          colorLight: '#ffffff',
          correctLevel: QRCode.CorrectLevel.H
      })
    },
    // 刷新二维码
    refresh(val) {
      this.creatQrCode(val)
    },
    async timeFresh() {
      // console.log(this.time)
      await this.getOrderInfo()
      setTimeout(() => {
        this.$refs.countDown.reset()
      }, 2000)
    }
  },
  watch: {
  }
}
</script>

<style lang="less" scoped>

.qrcode-link{
  min-height: 100vh;
  background: url('@/assets/qrcodeLink.png') no-repeat;
  background-size: 100% 100%;
  padding: 60px 30px 20px;
  overflow: auto;
  .red{
    color: red;
    display: flex;
    justify-content: center;
    .van-count-down{
      color: red;
      font-size: 12px;
      position: relative;
      top: -1px;
    }
  }
  .blue{
    color: #2e35ff;
  }

  h2{
    text-align: center;
    font-weight: 800;
    font-size: 22px;
    margin-bottom: 20px;
  }

  .order-info{
    border-radius: 30px;
    background: #fff;

    img{
      border-radius: 30px 30px 0 0;
      height: 170px;
      width: 100%;
    }
    .main-info{
      padding: 12px;
      box-sizing: border-box;
      .price{
        border-bottom: 1px solid #9b9090;
        margin-bottom: 10px;
        .item{
          display: flex;
          justify-content: space-between;
          font-weight: bold;
          .mount{
            color: red;
            >span{
              color: #9b9090;
              text-decoration: line-through;
            }
          }
        }
        .time{
          margin: 9px 0 10px;
          font-weight: bold;
          >:first-child{
            color: #b7b7b7;
            font-weight: bold;
          }
        }
      }
      .detail{
        .top{
          display: flex;
          justify-content: space-between;
          margin-bottom: 12px;
          >:first-child{
            font-weight: bold;
          }
          >:last-child{
            color: #2e35ff;
            display: flex;
            .van-icon{
              font-size: 18px;
              align-self: center;
            }
          }
        }
        p{
          width: 70%;
          overflow: hidden;   
          text-overflow:ellipsis;   
          white-space: nowrap;
          color: #9b9090;
          margin-bottom: 10px;
        }
      }
    }
  }
  .redeem-code{
    padding: 12px 20px;
    background: #fff;
    margin-top: 15px;
    border-radius: 20px;
    margin-bottom: 20px;
    .tit{
      display: flex;
      justify-content: space-between;
      >:first-child{
        // border-left: 5px solid #8ba7be;
        padding-left: 20px;
        position: relative;
        font-weight: bold;
      }
      >:first-child::before{
        content: '';
        display: inline-block;
        width: 10px;
        height: 30px;
        background: #8ba7be;
        border-radius: 15px;
        position: absolute;
        top: -4px;
        left: 0;
      }
      >:last-child{
        color: #2e35ff;
      }
    }

    .code{
      text-align: center;
      margin-top: 15px;
      display: flex;
      flex-direction: column;
      .qrcode{
        align-self: center;
      }

      P{
        font-size: 12px;
        margin-top: 6px;
      }
      .replay{
        margin-top: 10px;
        .van-icon{
          color: #2e35ff;
        }
      }
    }
    
  }
  .cus-info{
    background: #fff;
    padding: 20px;
    box-sizing: border-box;
    border-radius: 30px;
    .tit{
      background: #fff;
      display: flex;
      justify-content: space-between;
      margin-bottom: 20px;
      border-bottom: 1px solid #b7b7b7;
      padding-bottom: 10px;
      margin-bottom: 10px;
      >:first-child{
        padding-left: 20px;
        position: relative;
        font-weight: bold;
      }
      >:first-child::before{
        content: '';
        display: inline-block;
        width: 10px;
        height: 30px;
        background: #8ba7be;
        border-radius: 15px;
        position: absolute;
        top: -4px;
        left: 0;
      }
      >:last-child{
        color: #2e35ff;
      }
    }
    .info{
      ul{
        border-bottom: 1px solid #eeecec;
        margin-bottom: 10px;
        padding-bottom: 5px;
        li{
          padding: 4px 0;
          .first{
            display: inline-block;
            width: 115px;
            color: #9b9090;
          }
          .last{
            font-weight: bold;
          }
        }
      }
      ul:last-of-type{
        border-bottom: none;
      }
    }
  }
}
</style>
